body.cms-ui {
  .draftjs-buttons a,
  .draftjs-buttons a:hover,
  .callout-bg a,
  .callout-bg {
    color: $tertiary-text;
  }

  // Form
  .dropzone.lightgrey-bg-c2.color,
  .fileWidget .color {
    border-color: #878f93;
    color: $secondary-text;
  }

  // Buttons
  .ui.primary.button,
  .ui.primary.buttons .button {
    background-color: $primary;

    &:hover {
      background-color: $primary-dark;
    }
  }
}
