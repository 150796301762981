.block.contacts {
  .bg-primary {
    .block-header {
      .title,
      .description {
        color: $tertiary-text;
      }
    }

    .link-button {
      a.btn-tertiary {
        border-color: $secondary;
        background-color: $secondary;
        color: $primary !important;

        &:hover {
          background-color: shade-color($secondary, 7.5%);
        }
      }
    }
  }
}
