.it-footer {
  &-main,
  &-small-prints {
    background-color: $primary-dark;
  }

  &-main {
    .it-brand-wrapper {
      a {
        .icon {
          width: auto;
        }
      }
    }
    section {
      padding-right: 0;
      padding-left: 0;
      margin: 0 1rem;
    }
  }

  ul.it-footer-small-prints {
    &-list {
      padding-right: 0;
      padding-left: 0;
      border-top: 1px solid white;
      margin: 0 1rem;
    }
  }
}

.subsite-footer .text,
.subsite-footer .text a {
  color: white;
}
