.it-header-slim-wrapper {
  background: $site-header-slim-bg-color;

  .parent-site-menu {
    li.nav-item {
      border-right: 1px solid $tertiary-text;

      a.nav-link {
        color: $tertiary-text;
      }
    }
  }
}
